import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useAppDispatch } from "hooks";
import { setPageTitle, setPageType } from "store";
import { PageType } from "types";
import { AzureMediaPlayer } from "./azureMediaPlayer";
import styles from "./styles.module.scss";

interface Props {
  src: string;
  autoPlay: boolean;
  pageType: PageType;
  pageTitle: string;
  setIsEnded?: Dispatch<SetStateAction<boolean>>;
}

export const Video: FC<Props> = ({
  autoPlay,
  src,
  pageType,
  pageTitle,
  setIsEnded,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageType(pageType));
    dispatch(setPageTitle(pageTitle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div key={pageType} className={styles.container}>
      <AzureMediaPlayer src={src} autoPlay={autoPlay} setIsEnded={setIsEnded} />
    </div>
  );
};
