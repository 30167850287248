import { useEffect, useReducer } from "react";

enum TypingStatus {
  TYPING,
  DELETING,
}
type State = {
  placeholders: string[];
  text: string;
  placeholderIndex: number;
  characterIndex: number;
  status: TypingStatus;
};
type Action = {
  type: "update";
};
const reducer = (state: State, action: Action): State => {
  const fullText = state.placeholders[state.placeholderIndex];
  switch (action.type) {
    case "update":
      if (state.characterIndex < fullText.length) {
        return {
          ...state,
          text: state.text + fullText[state.characterIndex],
          characterIndex: state.characterIndex + 1,
        };
      }
      if (state.text.length > 0) {
        return {
          ...state,
          text: state.text.slice(0, state.text.length - 1),
          status: TypingStatus.DELETING,
        };
      }
      return {
        ...state,
        characterIndex: 0,
        placeholderIndex:
          (state.placeholderIndex + 1) % state.placeholders.length,
        status: TypingStatus.TYPING,
      };

    default:
      return state;
  }
};

const getJitter = () => Math.random() * 10;

// @TODO: refactor
const useTypingPlaceholder = (props: {
  /**
   * @default true
   */
  enabled?: boolean;
  placeholders: string[];
}): string => {
  const [state, dispatch] = useReducer(reducer, {
    text: "",
    characterIndex: 0,
    placeholderIndex: 0,
    placeholders: props.placeholders.map(
      // HACK: we want to pause at the end of the typing, so this helps us do
      // that without introducing a paused state.
      (placeholder) => `${placeholder}${" ".repeat(10)}`
    ),
    status: TypingStatus.TYPING,
  });

  const update = () => {
    dispatch({ type: "update" });
  };

  const isDeleting = state.status === TypingStatus.DELETING;
  const isEnabled = props.enabled;
  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined;
    const start = () => {
      timeout = setTimeout(
        () => {
          update();
          start();
        },
        isDeleting ? 40 : 100 + getJitter()
      );
    };
    if (isEnabled) {
      start();
    }
    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [isDeleting, isEnabled]);
  return state.text;
};

export default useTypingPlaceholder;
