import { FC, useEffect } from "react";
import styles from "./styles.module.scss";
import { useAppDispatch } from "hooks";
import { setPageTitle, setPageType } from "store";
import { PageType } from "types";

interface Props {
  pageTitle: string;
  pageType: PageType;
  src: string;
  blobUrl?: string;
  className?: string;
}

const { BlobBaseUrl } = window.config;

export const Image: FC<Props> = ({
  pageTitle,
  pageType,
  src,
  className,
  blobUrl = BlobBaseUrl,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageType(pageType));
    dispatch(setPageTitle(pageTitle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.body.style.backgroundColor = "black";

  return (
    <div className={`${styles.container} ${className}`}>
      {}
      {pageType === PageType.FutureStateArchitecture ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "black",
          }}
        >
          <img
            src={src?.includes("http") ? src : `${blobUrl}${src}`}
            alt={pageTitle}
            style={{ width: "auto", height: "auto" }}
          />
        </div>
      ) : (
        <img
          src={src?.includes("http") ? src : `${blobUrl}${src}`}
          alt={pageTitle}
          className={styles.image}
        />
      )}
    </div>
  );
};
