import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import { FC, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import styles from "./styles.module.scss";
import { Helmet } from "react-helmet";
import { Button } from "@progress/kendo-react-buttons";
import { SettingsContext } from "context";

interface Props {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header: FC<Props> = ({ expanded, setExpanded }) => {
  const { BlobBaseUrl } = window.config;
  const { pageTitle } = useAppSelector((state) => state.config);

  // const { currentDemo } = useContext(SettingsContext);

  const navigate = useNavigate();

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Helmet>
        <title>
          Microsoft Fabric with Azure Databricks DREAM Demo - {pageTitle}
        </title>
      </Helmet>
      <AppBar
        className={styles.appBar}
        style={{
          backgroundImage: `url(https://dreamdemoassets.blob.core.windows.net/openai/contoso_top_level_header_bg.png)`,
        }}
      >
        <AppBarSection>
          <div className={styles.customToolbar}>
            <Button icon="menu" fillMode="flat" onClick={handleClick} />
          </div>
        </AppBarSection>

        <AppBarSpacer className={styles.menuSpacer} />

        <AppBarSection>
          <div
            className={styles.logo}
            onClick={() => navigate(`/landing-page`)}
          >
            <img
              style={{
                marginLeft: 10,
                height: "auto",
                width: "auto",
              }}
              src={
                "https://openaidemoassets.blob.core.windows.net/fabric-assets/b0540c1f-f544-419d-bb2b-95ff0cae5756.png"
              }
              alt="logo"
            />
            {/* {!currentDemo?.disableTitle && (
              <p
                style={{
                  color: "white",
                  fontSize: 22,
                  fontWeight: 600,
                  marginBottom: 0,
                }}
              >
                {currentDemo?.title?.toUpperCase()}
              </p>
            )} */}
          </div>
        </AppBarSection>

        <AppBarSpacer />

        <AppBarSection>
          <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
            <img
              src={`${BlobBaseUrl}header_icon_search.png`}
              alt="header_icon_search"
            />
          </button>
        </AppBarSection>

        <AppBarSection>
          <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
            <img
              src={`${BlobBaseUrl}header_icon_alert.png`}
              alt="header_icon_alert"
            />
          </button>
        </AppBarSection>

        <AppBarSection>
          <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
            <img
              src={`${BlobBaseUrl}header_icon_settings.png`}
              alt="header_icon_settings"
            />
          </button>
        </AppBarSection>

        <AppBarSection>
          <NavLink
            to="/logout"
            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
          >
            <img
              src={`${BlobBaseUrl}header_icon_logout.png`}
              alt="header_icon_logout"
            />
          </NavLink>
        </AppBarSection>
      </AppBar>
    </>
  );
};
