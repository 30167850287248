export enum PageType {
  WorldMap,
  SalesPerformence,
  AnalyticsInLakehouse,
  FutureStateArchitecture,
  CityView,
  CDODashboardBefore,
  CurrentStateArchitecture,
  BenefitsOfTrident,
  ExecutiveDashboardBefore,
  IntroductionToTrident1,
  DataPlatform,
  IntroductionToTrident2,
  ExecutiveDashboardAfter,
  PainPoints,
  ScalableAnalytics,
  CDODashboardAfter,
  FinalVideo,
  StoreVideo,
  LandingPage,
  SocialMediaCampaign,
  IFrame,
  ProductRecommendation,
  ChatBot,
  CallCenter,
  CallCenterRalph,
  RetailCallCenter,
  RetailCohortAnalysis,
  RetailFormRecognizer,
  RetailIncidentInsights,
  HealthCareContactCenter,
  HealthCareFormRecognizer,
  HealthCareClinicalNotes,
  HealthCareHospitalIncidentInsights,
  HealthCareCohortAnalysis,
  CohortAnalysis,
  TextAnalytics,
  FormRecognizer,
  SmartFactory,
  FlorenceModel,
  AzureGeoSpatialForFSI,
  FSICEODashboard,
  GeoSpatialReport,
  Disclaimer,
  HealthCareArchitecture,
  ManufacturingArchitecture,
  FSIArchitecture,
  SustainabilityArchitecture,
  PublicFinanceArchitecture,
  DatameshArchitecture,
  Retail2Architecture,
  MGDCArchitecture,
  AnalyticsInMIDPArchitecture,
  SQLMigrationArchitecture,
  PowerBIDifferentiatorsArchitecture,
  MediaArchitecture,
  EndToEndPerformanceMonitoring,
  PowerBIWithAzureSynapseAnalyticsServerlessSQLPool,
  AzureSynapseDataExplorer,
  CommunicationAnalytics,
  RowlevelSecurity,
  ColumnlevelSecurity,
  DynamicDataMasking,
  SentimentAnalytics,
  RealTimeInStoreAnalytics,
  HOFHTAPReport,
  WithoutHTAP,
  WithHTAP,
  HTAP,
  CampaignAnalytics,
  CampaignAndSalesData,
  LowCodeDatamart,
  DataSecurity,
  DeploymentPipelines,
  ESGDashboard,
  OrgChart,
  Lakehouse,
  DataWarehouse,
  FinanceRevenueAndProfitability,
  CustomerChurnReport,
  CampaignAnalyticsReport,
  HRAnalyticsReport,
  OperationsReport,
  ITReport,
  OneLakeEx,
  OneLakeArchitectureDiagram,
  OneLakeDiagram,
  SalesPerformance,
}
