import { FC, useContext, useEffect, useState } from "react";
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, Popup } from "components";
import { useAppDispatch, useAppSelector } from "hooks";
import { login, setPageTitle } from "store";
import { SettingsContext } from "context";
import styles from "./styles.module.scss";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Disclaimer } from "pages/Disclaimer";
import axios from "axios";

const { BackendAPIUrl } = window.config;

const USERNAME = "april@contoso.com";
const PASSWORD = "12345";

export const Login: FC = () => {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector((state) => state.login.loggedIn);
  const [username, setUsername] = useState("april@wideworldimporters.com");
  const [value, setValue] = useState(false);
  const [visible, setVisible] = useState(false);
  // const { currentDemo } = useContext(SettingsContext);
  const [isError, setIsError] = useState(false);

  // useEffect(() => {
  //   if (currentDemo?.userName) setUsername(currentDemo?.userName);
  // }, [currentDemo]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (loggedIn) {
      navigate(`/landing-page`);
    }
    dispatch(setPageTitle("Login"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = ({ values }: FormSubmitClickEvent) => {
    setIsError(false);
    if (!value) {
      return setIsError(true);
    }
    if (values.username && values.password) {
      localStorage.setItem(
        "LoggedInUser",
        JSON.stringify({
          username: values.username,
          password: values.password,
        })
      );

      if (values.username === USERNAME && values.password === PASSWORD) {
        dispatch(login());
        navigate(location.state?.from || `/landing-page`, {
          replace: true,
        });
      } else {
        setIsError(true);
      }
    } else {
      setIsError(true);
    }
  };

  return (
    <>
      <div
        className={styles.loginWrapper}
        style={{
          backgroundImage: `url(https://dreamdemoassets.blob.core.windows.net/openai/contoso_login_page_background.png)`,
        }}
      >
        <div className={styles.loginFormContainer}>
          <img
            src={
              "https://openaidemoassets.blob.core.windows.net/fabric-assets/fac5ba67-4706-46df-bda9-55ec20c99f2a.png"
            }
            alt="box"
          />

          <Form
            initialValues={{
              username: USERNAME,
              password: PASSWORD,
            }}
            key={username}
            onSubmitClick={handleSubmit}
            render={({ onSubmit }: FormRenderProps) => (
              <FormElement
                style={{
                  color: "white",
                }}
              >
                <fieldset className="k-form-fieldset">
                  <Field
                    id="username"
                    name="username"
                    label="Username"
                    type="email"
                    placeholder="Username"
                    component={Input}
                  />
                  <Field
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="Password"
                    component={Input}
                  />
                  {isError && (
                    <FieldWrapper>
                      <Error style={{ color: "#ffc000" }}>
                        {!value
                          ? "Please accept the terms and conditions."
                          : "Invalid username or password. Please try again."}
                         
                      </Error>
                    </FieldWrapper>
                  )}
                  {}
                  <FieldWrapper
                    style={{
                      display: "flex",
                      gap: 8,
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      className={styles.checkBox}
                      value={value}
                      onChange={(e) => {
                        setIsError(false);
                        setValue(e.value);
                      }}
                    />
                    <Label>
                      I agree with the&nbsp;
                      <span
                        className={styles.tnc}
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        terms and conditions.
                      </span>
                    </Label>
                  </FieldWrapper>

                  <div className="k-form-buttons">
                    <Button
                      className={styles.loginBtn}
                      type={"submit"}
                      onClick={onSubmit}
                    >
                      Login
                    </Button>
                  </div>
                </fieldset>
              </FormElement>
            )}
          />
          <Popup
            showPopup={visible}
            onClose={() => {
              // setValue(true);
              setVisible(false);
            }}
            title="Disclaimer"
          >
            <Disclaimer setVisible={setVisible} setValue={setValue} />
          </Popup>
        </div>
      </div>
    </>
  );
};
