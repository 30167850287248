import React, { CSSProperties, useContext } from "react";
import startCase from "lodash.startcase";
import styles from "./styles.module.scss";
import { eq } from "fp-tools";
import { rootPath, urlify } from "utilities";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AuthContext, SettingsContext } from "context";
import { useArrows } from "hooks";
import { toolTips } from "common";

const labelAchronymList = [
  "esg",
  "msci",
  "hof",
  "cro",
  "cco",
  "cfo",
  "ceo",
  "ml",
  "cdp",
  "vp",
];

export type ArrowProps = {
  className?: string;
  to: string;
  linkStyles?: CSSProperties;
  name: string;
  openInNewTab?: boolean;
  type?: "default" | "skip" | "custom";
  tooltip?: string;
};

const { BlobBaseUrl } = window.config;

export const Arrow: React.FC<ArrowProps> = ({
  className,
  name,
  openInNewTab,
  to,
  type = "default",
  tooltip,
}) => {
  const { trackNavigation } = useContext(AuthContext);
  // const { currentDemo } = useContext(SettingsContext);
  // const { tooltips } = useArrows();
  // arrowUrl :: string -> string
  const arrowUrl = (arrowName: string) => `${BlobBaseUrl}${arrowName}`;

  // currentPath :: string -> string
  const currentPath = (s: string) =>
    name.includes("fast") ? "Fast Forward" : startCase(rootPath(s));

  // skip :: string -> boolean
  const skip = eq("skip");

  // def :: string -> boolean
  const def = eq("default");

  const href: string = to.includes("http") ? to : urlify(to);

  const label: string = to.includes("http")
    ? tooltip ?? "External Link"
    : currentPath(urlify(to))
        .split(" ")
        .map((w: string) =>
          labelAchronymList.indexOf(w.toLowerCase()) > -1 ? w.toUpperCase() : w
        )
        .join(" ");

  const src: string = skip(type) ? arrowUrl("fastforward.png") : arrowUrl(name);

  const arrowClass: string = skip(type)
    ? styles.arrowSkip
    : className ?? styles.arrowTopRight;

  const isLabel = to.includes("http");

  return (
    <Tooltip
      tooltipClassName={styles.tooltip}
      position={skip(type) || def(type) ? "left" : "bottom"}
      anchorElement="target"
      key={href}
    >
      <a
        className={arrowClass}
        href={isLabel ? href : `/#${href}`}
        target={openInNewTab ? "_blank" : "_self"}
        rel="noreferrer"
      >
        {isLabel && <img title={label} src={src} alt="Nav Arrow" />}
        {toolTips?.map((item) => {
          if (item?.url === to) {
            return (
              <div onClick={() => trackNavigation(item.value)}>
                <img
                  key={item.id}
                  title={item.value}
                  src={src}
                  alt="Nav Arrow"
                />
              </div>
            );
          }
          return null;
        })}
      </a>
    </Tooltip>
  );
};
