import * as React from "react";
import {
  Drawer as KendoDrawer,
  DrawerItem,
  DrawerItemProps,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { getMenu } from "common";
import { FC } from "react";
import { SettingsContext } from "context";

const { IconBlobBaseUrl } = window.config;

interface Props {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const menus: any = [];

const CustomItem: FC<DrawerItemProps> = (props) => {
  const { visible, ...others } = props;
  // const { currentDemo } = React.useContext(SettingsContext);
  const arrowDir = props["data-expanded"]
    ? "k-i-arrow-chevron-down"
    : "k-i-arrow-chevron-right";
  const menu = getMenu();

  menu.forEach((menuItem) => {
    const obj = {
      text: menuItem.menuName,
      icon: menuItem.menuIcon,
      id: menuItem.id,
      selected: false,
      ...(menuItem.href
        ? { route: menuItem.href }
        : { "data-expanded": false }),
    };
    menus.push(obj);

    if (menuItem.menuItems) {
      menuItem.menuItems.forEach((item) => {
        const childObj = {
          text: item.title,
          icon: item.icon,
          parentId: menuItem.id,
          id: item.id,
          selected: false,
          route: item.href,
        };

        menus.push(childObj);
      });
    }

    const separatorObj = { separator: true };

    menus.push(separatorObj);
  });

  return (
    <React.Fragment>
      {props.visible === false ? null : (
        <DrawerItem {...others} style={{ position: "relative" }}>
          <img src={`${IconBlobBaseUrl}${props.icon}`} alt={props.icon} />

          <span className={"k-item-text"}>{props.text}</span>
          {props["data-expanded"] !== undefined && (
            <span
              className={"k-icon " + arrowDir}
              style={{ position: "absolute", right: 10, width: 25, height: 25 }}
            />
          )}
        </DrawerItem>
      )}
    </React.Fragment>
  );
};

export const Drawer: FC<Props> = ({ expanded, setExpanded }) => {
  const navigate = useNavigate();

  const [items, setItems] = React.useState<Array<any>>(menus);

  const onSelect = (ev: DrawerSelectEvent) => {
    const currentItem = ev.itemTarget.props;
    const isParent = currentItem["data-expanded"] !== undefined;
    const nextExpanded = !currentItem["data-expanded"];

    const newData = items.map((item) => {
      const {
        selected,
        "data-expanded": currentExpanded,
        id,
        ...others
      } = item;
      const isCurrentItem = currentItem.id === id;
      return {
        selected: isCurrentItem,
        "data-expanded":
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
      };
    });

    navigate(ev.itemTarget.props.route);
    setItems(newData);
  };

  const data = items.map((item) => {
    const { parentId, ...others } = item;
    if (parentId !== undefined) {
      const parent = items.find((parent) => parent.id === parentId);
      return {
        ...others,
        visible: parent["data-expanded"],
      };
    }
    return item;
  });

  return (
    <div className={styles.container}>
      <KendoDrawer
        expanded={expanded}
        mode="overlay"
        onOverlayClick={() => setExpanded(false)}
        width={300}
        items={data}
        item={CustomItem}
        onSelect={onSelect}
        style={{ position: "absolute" }}
      ></KendoDrawer>
    </div>
  );
};
