import { createSlice } from "@reduxjs/toolkit";

interface LoginState {
  loggedIn: boolean;
  componentHistory: string[];
}

const initialState: LoginState = {
  loggedIn: localStorage.getItem("LoggedInUser") ? true : false,
  componentHistory: [],
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login: (state: LoginState) => {
      state.loggedIn = true;
    },
    logout: (state: LoginState) => {
      state.loggedIn = false;
      localStorage.removeItem("LoggedInUser");
    },
  },
});

export const { login, logout } = loginSlice.actions;
export default loginSlice.reducer;
