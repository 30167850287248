import React, { createContext, useEffect, FC, ReactNode } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import axios from "axios";

const { APIUrl } = window.config;
let appInsights: ApplicationInsights | null = null;

interface Props {
  children: ReactNode;
}

export const AuthContextProvider: FC<Props> = ({ children }) => {
  const initAppInsights = async () => {
    const { data: appInsightsKey } = await axios.get(
      `${APIUrl}/api/appinsightskey`
    );
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsKey,
      },
    });
    appInsights.loadAppInsights();
  };

  const trackDemoLoad = () =>
    trackEvent("Azure Open AI Demo > DemoLoaded", "demo loaded");

  const trackNavigation = (navPageTitle: string) => {
    trackEvent(
      `Azure Open AI Demo > UserNavigated-${navPageTitle}`,
      " mega map chiclets"
    );

    trackPageView(
      `Azure Open AI Demo > PageViewed-${navPageTitle}`,
      " navigation event tracked to app insights."
    );
  };

  const trackMegaMapClick = (event: string) => {
    trackEvent(
      `Azure Open AI Demo > UserClicked-${event}`,
      " mega map chiclets"
    );
  };

  const trackEvent = async (trackingEvent: string, msg: string) => {
    if (!appInsights) {
      await initAppInsights();
    }
    if (appInsights && trackingEvent) {
      appInsights.trackEvent({ name: trackingEvent });
    }
  };

  const trackPageView = async (event: string, msg: string) => {
    if (!appInsights) {
      await initAppInsights();
    }
    if (appInsights && event) {
      appInsights.trackPageView({ name: event });
    }
  };

  useEffect(() => {
    initAppInsights();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        trackDemoLoad,
        trackNavigation,
        trackMegaMapClick,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthContext = createContext({
  trackDemoLoad: () => {},
  trackNavigation: (title: string) => {},
  trackMegaMapClick: (title: string) => {},
});
