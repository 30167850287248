import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PageType } from "types";

// Define a type for the slice state
interface ConfigState {
  pageType: PageType;
  pageTitle: string;
}

// Define the initial state using that type
const initialState: ConfigState = {
  pageType: PageType.LandingPage,
  pageTitle: "Landing Page",
};

export const configSlice = createSlice({
  name: "persona",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setPageTitle: (state, action: PayloadAction<string>) => {
      state.pageTitle = action.payload;
    },
    setPageType: (state, action: PayloadAction<PageType>) => {
      state.pageType = action.payload;
    },
  },
});

export const { setPageType, setPageTitle } = configSlice.actions;

export default configSlice.reducer;
