import { CSSProperties, FC, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

import { Drawer, Header, NavBar, RenderArrows } from "components";
import styles from "./styles.module.scss";
import { SettingsContext } from "context";
import { useArrows } from "hooks";
import { arrowConfig, routeDefinitions } from "common";

export const Layout: FC = () => {
  const [expanded, setExpanded] = useState(false);
  // const { currentDemo } = useContext(SettingsContext);
  // const { arrowConfig, routeDefinitions } = useArrows();

  return (
    <div>
      <Header expanded={expanded} setExpanded={setExpanded} />
      <NavBar />
      <Drawer expanded={expanded} setExpanded={setExpanded} />
      {/* {currentDemo?.guid && ( */}
      <RenderArrows
        arrowConfig={arrowConfig}
        routeDefinitions={routeDefinitions}
      />
      {/* )} */}
      <div className={styles.container}>
        <Outlet />
      </div>
    </div>
  );
};
