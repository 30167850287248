import { FC } from "react";
import styles from "./styles.module.scss";

export const RotateBanner: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.rotateCard}>
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
          fill="white"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#fff"
            stroke="none"
          >
            <path
              d="M2075 5101 c-370 -66 -696 -255 -937 -544 -71 -86 -62 -133 41 -198
  77 -49 92 -44 204 76 122 130 240 219 382 289 198 98 365 137 585 137 489 1
  937 -277 1159 -719 69 -138 131 -350 131 -451 l0 -39 -94 5 c-91 6 -95 5 -120
  -20 -19 -19 -26 -36 -26 -66 0 -35 13 -56 126 -200 69 -89 137 -169 151 -178
  40 -26 121 -23 163 7 67 46 285 218 301 236 38 44 27 114 -22 139 -15 8 -63
  20 -106 26 -43 6 -84 16 -91 24 -8 7 -18 50 -23 96 -40 376 -184 676 -453 944
  -240 240 -507 380 -831 435 -152 26 -395 26 -540 1z"
            />
            <path
              d="M470 3731 c-73 -23 -137 -79 -169 -150 -15 -33 -16 -187 -19 -1675
  -1 -1093 1 -1658 8 -1695 14 -76 55 -139 114 -176 l49 -30 916 -3 c606 -2 928
  1 951 7 52 15 119 77 146 135 l24 51 0 1665 c0 1116 -4 1677 -10 1702 -16 56
  -69 119 -125 147 l-49 26 -906 2 c-498 1 -916 -2 -930 -6z m1652 -438 c17 -15
  18 -68 18 -1285 0 -1255 0 -1268 -20 -1288 -20 -20 -33 -20 -733 -20 -713 0
  -714 0 -733 21 -18 20 -19 58 -22 1268 -1 685 0 1256 3 1268 13 56 -25 53 752
  53 653 0 718 -1 735 -17z m-655 -2854 c42 -26 77 -99 69 -143 -21 -110 -142
  -171 -226 -114 -74 51 -98 124 -62 194 25 48 40 62 89 80 44 15 85 10 130 -17z"
            />
            <path
              d="M2630 2305 l0 -175 868 0 869 0 21 -23 22 -23 0 -708 0 -707 -25 -24
  -24 -25 -866 0 -865 0 0 -175 0 -175 980 0 c670 0 995 3 1030 11 67 14 128 62
  165 129 l30 55 0 910 c0 819 -2 914 -16 946 -23 51 -75 107 -123 132 l-41 22
  -1012 3 -1013 2 0 -175z"
            />
          </g>
        </svg>
        <p>Best viewed in landscape mode</p>
      </div>
    </div>
  );
};
