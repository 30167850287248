import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import {
  AuthContextProvider,
  SettingsContextProvider,
  DemoContextProvider,
} from "context";
import { initializeIcons } from "@fluentui/react";
import { MicContextProvider } from "context/MicContext";

// Required with new version of typescript
declare global {
  interface Window {
    config: any;
  }

  interface Microsoft {
    Maps: any;
  }
}
initializeIcons();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        {/* <SettingsContextProvider> */}
        {/* <DemoContextProvider> */}
        <AuthContextProvider>
          <MicContextProvider>
            <App />
          </MicContextProvider>
        </AuthContextProvider>
        {/* </DemoContextProvider> */}
        {/* </SettingsContextProvider> */}
      </Provider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
