import { FC } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import styles from "./styles.module.scss";

interface Props {
  showPopup: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  className?: string;
}

export const Popup: FC<Props> = ({
  onClose,
  showPopup,
  title,
  children,
  className,
}) => {
  return (
    <>
      {showPopup && (
        <Dialog
          className={`${styles.popupContainer} ${className}`}
          title={title}
          onClose={onClose}
        >
          <div className={styles.childContainer}>{children}</div>
        </Dialog>
      )}
    </>
  );
};
