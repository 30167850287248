import { Image } from "components";
import { useAppDispatch } from "hooks";
import { useState, useEffect } from "react";
import { setPageType, setPageTitle } from "store";
import { PageType, EmbedType } from "types";
import { PowerBiServiceInstance } from "utilities";
import styles from "./styles.module.scss";

interface Props {
  pageTitle: string;
  pageType: PageType;
  dashboardId: string;
  topReportId: string;
  topReportName: string;
}

const width = 1920;
const height = 1280;

export const DashboardWithReport: React.FC<Props> = ({
  pageType,
  pageTitle,
  dashboardId,
  topReportId,
  topReportName,
}) => {
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageType(pageType));
    dispatch(setPageTitle(pageTitle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dashboardLoading) {
      return;
    }

    setDashboardLoading(true);
    try {
      PowerBiServiceInstance.load(dashboardId, {
        type: EmbedType.Dashboard,
        elementId: dashboardId,
        height,
        width,
      });
      PowerBiServiceInstance.load(topReportId, {
        type: EmbedType.Report,
        elementId: topReportId,
        pageName: topReportName,
        height,
        width,
      });

      setDashboardLoading(false);
    } catch (error) {
      setDashboardLoading(false);
    }
  }, [dashboardId, dashboardLoading, pageType, topReportId, topReportName]);

  useEffect(() => {
    if (dashboardLoading) {
      return;
    }
    PowerBiServiceInstance.switchPage(dashboardId, "");
  }, [dashboardId, dashboardLoading]);

  return (
    <div className={styles.container}>
      {topReportId !== "" && (
        <div id={topReportId} className={styles.topReport} />
      )}

      {dashboardId !== "" && (
        <div id={dashboardId} className={styles.dashboard} />
      )}

      {!dashboardId && pageType === PageType.ExecutiveDashboardBefore && (
        <Image
          pageTitle={pageTitle}
          pageType={pageType}
          src="Before_Dashboard_Fabric.gif"
          className={styles.gifContainer}
        />
      )}

      {!dashboardId && pageType === PageType.ExecutiveDashboardAfter && (
        <Image
          pageTitle={pageTitle}
          pageType={pageType}
          src="After_Dashboard_Fabric.gif"
          className={styles.gifContainer}
        />
      )}
    </div>
  );
};

export default DashboardWithReport;
