import { Menu } from "types";

export const getMenu = (demoId?: string, title?: string) => {
  return [
    {
      id: 1,
      menuIcon: "icon1.png",
      menuName: "Intro & CEO Dashboard",
      menuItems: [
        {
          id: 2,
          title: `${title ?? "Contoso"} Slogan`,
          icon: "icon1_2.png",
          href: demoId ? `/${demoId}/landing-page` : `/landing-page`,
        },
        {
          id: 3,
          title: "World Map",
          icon: "icon1_4.png",
          href: demoId ? `/${demoId}/world-map` : `/world-map`,
        },
        {
          id: 4,
          title: "Miami Beach",
          icon: "icon4_3.png",
          href: demoId ? `/${demoId}/miami-beach` : `/miami-beach`,
        },
        {
          id: 5,
          title: "Executive Dashboard - Before",
          icon: "icon1_5.png",
          href: demoId
            ? `/${demoId}/executive-dashboard-before`
            : `/executive-dashboard-before`,
        },
        {
          id: 6,
          title: "Org Chart",
          icon: "icon1_3.png",
          href: demoId ? `/${demoId}/org-chart` : `/org-chart`,
        },
      ],
    },
    {
      id: 7,
      menuIcon: "icon2.png",
      menuName: "Before Using Microsoft Fabric",
      menuItems: [
        {
          id: 8,
          title: "Current State Architecture",
          icon: "icon12.png",
          href: demoId
            ? `/${demoId}/current-state-architecture`
            : `/current-state-architecture`,
        },
        {
          id: 9,
          title: "CDO Top of Mind",
          icon: "icon12.png",
          href: demoId
            ? `/${demoId}/scalable-analytics`
            : `/scalable-analytics`,
        },

        {
          id: 10,
          title: "CDO Metrics - Current State",
          icon: "icon1_5.png",
          href: demoId
            ? `/${demoId}/cdo-dashboard-before`
            : `/cdo-dashboard-before`,
        },
        // {
        //   id: 13,
        //   title: "Introduction to Microsoft Fabric - 1",
        //   icon: "icon12.png",
        //   href: demoId ? `/${demoId}/introduction-to-microsoft-fabric-1` : `d}/introduction-to-microsoft-fabric-1`,
        // },
      ],
    },
    {
      id: 7,
      menuIcon: "icon2.png",
      menuName: "With Microsoft Fabric",
      menuItems: [
        {
          id: 10,
          title: "Analytics in Lakehouse",
          icon: "icon12.png",
          href: demoId
            ? `/${demoId}/analytics-in-lakehouse`
            : `/analytics-in-lakehouse`,
        },
        {
          id: 11,
          title: "OneLake Diagram",
          icon: "icon12.png",
          href: demoId ? `/${demoId}/one-lake-diagram` : `/one-lake-diagram`,
        },
        {
          id: 12,
          title:
            "Microsoft Fabric and Azure Databricks DREAM Demo Architecture",
          icon: "icon12.png",
          href: demoId ? `/${demoId}/demo-architecture` : `/demo-architecture`,
        },
        {
          id: 13,
          title: "Lakehouse Creation Demo (Click-by-Click)",
          icon: "icon11_1.png",
          href: demoId ? `/${demoId}/lake-house` : `/lake-house`,
        },
        {
          id: 28,
          title: "OneLake Explorer Demo (Click-by-Click)",
          icon: "icon11_1.png",
          href: demoId ? `/${demoId}/onelake-explorer` : `/onelake-explorer`,
        },
        {
          id: 14,
          title: "Data Warehouse Creation Demo (Click-by-Click)",
          icon: "icon11_1.png",
          href: demoId ? `/${demoId}/dataware-house` : `/dataware-house`,
        },
      ],
    },
    {
      id: 7,
      menuIcon: "icon2.png",
      menuName: "Power BI Reports by Departments",
      menuItems: [
        {
          id: 17,
          title: "Sales - Customer Churn Report",
          icon: "icon12.png",
          href: demoId
            ? `/${demoId}/customer-churn-report`
            : `/customer-churn-report`,
        },
        {
          id: 29,
          title: "Sales - Sales Performance",
          icon: "icon12.png",
          href: demoId
            ? `/${demoId}/sales-performence-report`
            : `/sales-performence-report`,
        },
        {
          id: 16,
          title: "Finance - Revenue and Profitability",
          icon: "icon12.png",
          href: demoId
            ? `/${demoId}/finance-revenue-and-profitability`
            : `/finance-revenue-and-profitability`,
        },

        {
          id: 18,
          title: "Marketing - Campaign Analytics",
          icon: "icon12.png",
          href: demoId
            ? `/${demoId}/campaign-analytics-report`
            : `/campaign-analytics-report`,
        },
        {
          id: 19,
          title: "HR - Employee Management",
          icon: "icon12.png",
          href: demoId
            ? `/${demoId}/hr-analytics-report`
            : `/hr-analytics-report`,
        },
        {
          id: 20,
          title: "Operations - Warehouse Operating Expense",
          icon: "icon12.png",
          href: demoId ? `/${demoId}/operations-report` : `/operations-report`,
        },
        {
          id: 21,
          title: "IT - IT Operations",
          icon: "icon12.png",
          href: demoId ? `/${demoId}/it-report` : `/it-report`,
        },
      ],
    },
    {
      id: 22,
      menuIcon: "icon10_1.png",
      menuName: "Post Microsoft Fabric Implementation",
      menuItems: [
        {
          id: 24,
          title: "Sales Event Video",
          icon: "icon_video.png",
          href: demoId ? `/${demoId}/store-overview` : `/store-overview`,
        },
        {
          id: 23,
          title: "CDO Metrics - After",
          icon: "icon11_1.png",
          href: demoId
            ? `/${demoId}/cdo-dashboard-after`
            : `/cdo-dashboard-after`,
        },

        {
          id: 25,
          title: "Executive Dashboard - After",
          icon: "icon11_1.png",
          href: demoId
            ? `/${demoId}/executive-dashboard-after`
            : `/executive-dashboard-after`,
        },
        {
          id: 26,
          title: "Finale Video",
          icon: "icon_video.png",
          href: demoId ? `/${demoId}/final-video` : `/final-video`,
        },
      ],
    },
    {
      id: 27,
      menuIcon: "header_icon_logout.png",
      menuName: "Logout",
      href: demoId ? `/${demoId}/logout` : `/logout`,
    },
  ] as Menu[];
};
