import { AuthContext } from "context";
import { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styles from "components/Image/styles.module.scss";
import { PageType } from "types";
import {
  DashboardWithReport,
  IFrame,
  Image,
  Layout,
  Report,
  RequireAuth,
  RotateBanner,
  Video,
} from "./components";
import { CityView, Home, Login, Logout } from "./pages";
import "./app.scss";

const {
  WorldMapReportID,
  WorldMapReportSectionName,
  CDODashboardBeforeReportID,
  CDODashboardBeforeReportName,
  ExecutiveDashboardBeforeTopReportID,
  ExecutiveDashboardBeforeTopReportName,
  ExecutiveDashboardAfterTopReportID,
  ExecutiveDashboardAfterTopReportName,
  CDODashboardAfterReportID,
  CDODashboardAfterReportName,
  FinalVideoUrl,
  StoreVideoUrl,
  FinanceRevenueAndProfitabilityReportID,
  FinanceRevenueAndProfitabilityReportSectionName,
  CustomerChurnReportID,
  CustomerChurnReportSectionName,
  CampaignAnalyticsReportID,
  CampaignAnalyticsReportSectionName,
  HRAnalyticsReportID,
  HRAnalyticsReportSectionName,
  OperationsReportID,
  OperationsReportSectionName,
  ITReportID,
  ITReportSectionName,
  SalesPerformanceReportID,
  SalesPerformanceReportSectionName,
  ExecutiveDashboardBeforeDashboardID,
  ExecutiveDashboardAfterDashboardID,
  SalesPerformenceReportID,
  SalesPerformenceReportSectionName,
} = window.config;

function App() {
  const { trackDemoLoad } = useContext(AuthContext);

  useEffect(() => {
    document.body.style.setProperty("--primary-color", `#00a1cbff`);
    document.body.style.setProperty("--secondary-color", `#004b76ff`);
    document.body.style.setProperty(
      "--tab-text-color",
      `rgba(255, 255, 255, 1)`
    );
    document.body.style.setProperty("--navBar-primary-color", `#004b76ff`);
    document.body.style.setProperty("--navBar-secondary-color", `#00a1cbff`);
    document.body.style.setProperty("--tab-primary-color", `#00a1cbff`);
    document.body.style.setProperty("--tab-secondary-color", `#004b76ff`);
    document.body.style.setProperty("--dropdown-primary-color", `#00a1cbff`);
    document.body.style.setProperty("--dropdown-secondary-color", `#004b76ff`);
    document.body.style.setProperty(
      "--dropdown-text-color",
      "rgba(255,255,255,1)"
    );
    document.body.style.setProperty(
      "--navBar-text-color",
      "rgba(255,255,255,1)"
    );
    document.body.style.setProperty(
      "--header-image",
      `url(https://dreamdemoassets.blob.core.windows.net/openai/contoso_top_level_header_bg.png)`
    );
    document.body.style.setProperty(
      "--login-text-box-img",
      'url("https://dreamdemoassets.blob.core.windows.net/openai/contoso_login_text_box.png")'
    );
    document.body.style.setProperty(
      "--scrollBar-primary-color",
      "rgba(255, 255, 255, 0.5)"
    );
    document.body.style.setProperty(
      "--scrollBar-secondary-color",
      "rgba(255, 255, 255, 0.5)"
    );
  }, []);

  useEffect(() => {
    trackDemoLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes>
        {/* <Route path="settings" element={<Settings />} /> */}
        <Route path="logout" element={<Logout />} />
        {/* <Route path="" element={<Home />} /> */}
        <Route path="login" element={<Login />} />
        <Route
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route
            path="org-chart"
            element={
              <Image
                pageTitle="Org Chart"
                pageType={PageType.OrgChart}
                src={
                  "https://openaidemoassets.blob.core.windows.net/fabric-assets/a185039d-8e07-403a-a9aa-db27d7cafea9.png"
                }
              />
            }
          />

          <Route
            path="world-map"
            element={
              <Report
                pageTitle="World Map"
                id={WorldMapReportID}
                pageType={PageType.WorldMap}
                name={WorldMapReportSectionName}
              />
            }
          />

          <Route
            path="cdo-dashboard-before"
            element={
              <Report
                pageType={PageType.CDODashboardBefore}
                pageTitle="CDO Metrics - Current State"
                id={CDODashboardBeforeReportID}
                name={CDODashboardBeforeReportName}
              />
            }
          />

          <Route
            path="future-state-architecture"
            element={
              <Image
                pageTitle="Future State Architecture"
                pageType={PageType.FutureStateArchitecture}
                src="ArchitectureDiaV2-1final.gif"
              />
            }
          />

          <Route
            path="lake-house"
            element={
              <IFrame
                url="https://regale.cloud/Microsoft/viewer/2262/microsoft-fabric-embedded-demo/index.html#/0/0"
                pageTitle="Lakehouse Creation Demo(Click-by-Click)"
                pageType={PageType.Lakehouse}
              />
            }
          />
          <Route
            path="dataware-house"
            element={
              <IFrame
                url="https://regale.cloud/Microsoft/viewer/2262/microsoft-fabric-embedded-demo/index.html#/2/0"
                pageTitle="Data Warehouse Creation Demo(Click-by-Click)"
                pageType={PageType.DataWarehouse}
              />
            }
          />
          <Route
            path="current-state-architecture"
            element={
              <Image
                pageTitle="Current State Architecture"
                pageType={PageType.CurrentStateArchitecture}
                src="Multiple_Siloed_PNG.png"
              />
            }
          />

          <Route
            path="benefits-of-microsoft-fabric"
            element={
              <Image
                pageTitle="Benefits of Microsoft Fabric"
                pageType={PageType.BenefitsOfTrident}
                src="Benefits_of_Trident1.png"
              />
            }
          />

          <Route
            path="executive-dashboard-before"
            element={
              <DashboardWithReport
                dashboardId={ExecutiveDashboardBeforeDashboardID}
                pageTitle="Executive Dashboard - Before"
                topReportId={ExecutiveDashboardBeforeTopReportID}
                pageType={PageType.ExecutiveDashboardBefore}
                topReportName={ExecutiveDashboardBeforeTopReportName}
              />
            }
          />

          <Route
            path="introduction-to-microsoft-fabric-1"
            element={
              <Image
                pageTitle="Introduction to Microsoft Fabric"
                pageType={PageType.IntroductionToTrident1}
                src="silver_lining.png"
              />
            }
          />

          <Route
            path="data-platform-for-the-age-of-AI"
            element={
              <Image
                pageTitle="The Data Platform for the age of AI"
                pageType={PageType.DataPlatform}
                src="Project MF-new.png"
              />
            }
          />

          <Route
            path="introduction-to-microsoft-fabric"
            element={
              <Image
                pageTitle="Introduction to Microsoft Fabric"
                pageType={PageType.IntroductionToTrident2}
                src="Microsoft Fabric-new.png"
              />
            }
          />

          <Route
            path="executive-dashboard-after"
            element={
              <DashboardWithReport
                dashboardId={ExecutiveDashboardAfterDashboardID}
                pageTitle="Executive Dashboard - After"
                pageType={PageType.ExecutiveDashboardAfter}
                topReportId={ExecutiveDashboardAfterTopReportID}
                topReportName={ExecutiveDashboardAfterTopReportName}
              />
            }
          />

          <Route
            path="pain-points"
            element={
              <Image
                pageTitle="Pain Points"
                pageType={PageType.PainPoints}
                src="complications-points-in-current-state-architecture1.png"
              />
            }
          />

          <Route
            path="scalable-analytics"
            element={
              <Image
                pageTitle="CDO Top of Mind"
                pageType={PageType.ScalableAnalytics}
                src="Scalable analytics are complex and fragmented -new.png"
              />
            }
          />
          <Route
            path="onelake-explorer"
            element={
              <IFrame
                url="https://regale.cloud/Microsoft/viewer/2262/microsoft-fabric-embedded-demo/index.html#/1/0"
                pageTitle="OneLake Explorer Demo(Click-by-Click)"
                pageType={PageType.OneLakeEx}
              />
            }
          />
          <Route
            path="customer-churn-report"
            element={
              <Report
                background="white"
                pageType={PageType.CustomerChurnReport}
                pageTitle="Sales - Customer Churn Report"
                id={CustomerChurnReportID}
                name={CustomerChurnReportSectionName}
              />
            }
          />
          <Route
            path="sales-performence-report"
            element={
              <Report
                background="white"
                pageType={PageType.SalesPerformence}
                pageTitle="Sales - Sales Performance"
                id={SalesPerformenceReportID}
                name={SalesPerformenceReportSectionName}
              />
            }
          />
          <Route
            path="finance-revenue-and-profitability"
            element={
              <Report
                background="white"
                pageType={PageType.FinanceRevenueAndProfitability}
                pageTitle="Finance Revenue and Profitability"
                id={FinanceRevenueAndProfitabilityReportID}
                name={FinanceRevenueAndProfitabilityReportSectionName}
              />
            }
          />

          <Route
            path="campaign-analytics-report"
            element={
              <Report
                background="white"
                pageType={PageType.CampaignAnalyticsReport}
                pageTitle="Campaign Analytics Report"
                id={CampaignAnalyticsReportID}
                name={CampaignAnalyticsReportSectionName}
              />
            }
          />

          <Route
            path="hr-analytics-report"
            element={
              <Report
                background="white"
                pageType={PageType.HRAnalyticsReport}
                pageTitle="HR Analytics Report"
                id={HRAnalyticsReportID}
                name={HRAnalyticsReportSectionName}
              />
            }
          />

          <Route
            path="operations-report"
            element={
              <Report
                background="white"
                pageType={PageType.OperationsReport}
                pageTitle="Operations Report"
                id={OperationsReportID}
                name={OperationsReportSectionName}
              />
            }
          />

          <Route
            path="it-report"
            element={
              <Report
                background="white"
                pageType={PageType.ITReport}
                pageTitle="IT Report"
                id={ITReportID}
                name={ITReportSectionName}
              />
            }
          />

          <Route
            path="cdo-dashboard-after"
            element={
              <Report
                pageType={PageType.CDODashboardAfter}
                pageTitle="CDO Metrics - After"
                id={CDODashboardAfterReportID}
                name={CDODashboardAfterReportName}
              />
            }
          />

          <Route
            path="final-video"
            element={
              <Video
                pageTitle="Finale + Fireworks Video"
                pageType={PageType.FinalVideo}
                src={FinalVideoUrl}
                autoPlay={false}
              />
            }
          />

          <Route
            path="store-overview"
            element={
              <Video
                pageType={PageType.StoreVideo}
                pageTitle="Sales Event Video"
                src={StoreVideoUrl}
                autoPlay={false}
              />
            }
          />

          <Route path="miami-beach" element={<CityView />} />

          <Route
            path="landing-page"
            element={
              <Image
                pageTitle="Landing Page"
                pageType={PageType.LandingPage}
                src={
                  "https://nrfcdn.azureedge.net/FabricLandingApprovedOption.png"
                }
              />
            }
          />
        </Route>
        <Route path="*" element={<Navigate to={`/login`} />} />
      </Routes>
      <RotateBanner />
    </>
  );
}

export default App;
